import React, { forwardRef } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller } from 'react-hook-form';
import { useRecoilValue, useRecoilState } from 'recoil';

import { CellData } from 'components/dashboard/applicants/components/cell-data';
import {
    CheckboxComponent,
    DateInputComponent,
    Label,
    MoneyInputComponent,
    PercentageInputComponent,
    NumberInputComponent,
    PhoneInputComponent,
    PostalCodeInput,
    SelectV2 as Select,
    TextAreaInputComponent,
    TextInputComponent,
    MarkupTextAreaInputComponent,
} from 'components/forms';
import { YES_NO_OPTIONS, YES_NO_STRING_OPTIONS } from 'constants/appConstants';
import { useI18n } from 'providers/i18n/use-i18n';
import {
    getProblemsBySection,
    selectedApplication,
    getProblemsValidationMode,
    getUseProblemsValidation,
} from 'store/applications';
import { sectionSelectedState } from 'store/qualification';
import { getDefaultValue, dateNormalizer } from 'utils';
import { isFieldInvalid } from 'utils/validations/problems';

import type { Variant } from 'components/cell/cell';
import type { CellDataProps } from 'components/dashboard/applicants/components/cell-data';
import type { ProblemType } from 'constants/problem';
import type { MenuPlacement } from 'react-select';
import type { ApplicationProblemAugmented } from 'types/problems';

type OnChangeSelect = (() => void) | ((value: string) => void);

export type FieldTypeProp =
    | 'text'
    | 'number'
    | 'simpleNumber'
    | 'money'
    | 'phone'
    | 'date'
    | 'textarea'
    | 'markupTextarea'
    | 'select'
    | 'email'
    | 'boolean'
    | 'yesNo'
    | 'postalCode'
    | 'checkbox'
    | 'readonly'
    | 'hidden'
    | 'percentage';
export type EditableCellProps = CellDataProps & {
    name: string;
    label?: string;
    fieldType?: FieldTypeProp;
    isEditing?: boolean;
    options?: any[];
    namePrefix?: string;
    pattern?: string;
    maxLength?: number;
    // React-Selct options
    isClearable?: boolean;
    isMulti?: boolean;
    // Number Input
    min?: number;
    max?: number;
    disabled?: boolean;
    integer?: boolean;
    hidePlaceholder?: boolean;
    // Postal / Zip code
    countryCode?: string;
    error?: any;
    menuPlacement?: MenuPlacement;
    required?: boolean;
    round?: boolean;
    event?: any;
    problemType?: ProblemType;
    isFieldInvalidCompareFn?: (
        problems?: ApplicationProblemAugmented[]
    ) => boolean;
    autoFocus?: boolean;
    autoComplete?: HTMLInputElement['autocomplete'];
    groupedOptions?: boolean;
    squareCheckbox?: boolean;
    blockWeekends?: boolean;
    onChangeSelect?: OnChangeSelect;
};

const getCellVariant = (
    isFieldInvalid,
    showValidationProblems,
    showProblemsValidation,
    usePartialValidation,
    variant,
    required
): Variant => {
    if (showValidationProblems) {
        if (isFieldInvalid && showProblemsValidation) {
            // if it is required and returned by the BE we use variant=error
            // we show error when full validation is required
            if (required || !usePartialValidation) {
                return 'error';
            }

            return 'warning';
        }
    }

    return variant;
};

export const EditableCell = forwardRef(
    (
        {
            // eslint-disable-next-line react/prop-types
            name,
            // eslint-disable-next-line react/prop-types
            value,
            // eslint-disable-next-line react/prop-types
            isEditing = false,
            // eslint-disable-next-line react/prop-types
            fieldType = 'text',
            // eslint-disable-next-line react/prop-types
            options = [],
            // eslint-disable-next-line react/prop-types
            label,
            // eslint-disable-next-line
            namePrefix = '',
            // eslint-disable-next-line react/prop-types
            integer = false,
            isFieldInvalidCompareFn,
            problemType,
            groupedOptions = false,
            blockWeekends = false,
            ...rest
        }: EditableCellProps,
        ref: any
    ) => {
        const { showValidationProblems } = useFlags();
        const { i18n } = useI18n();
        const application = useRecoilValue(selectedApplication);
        const sectionSelected = useRecoilValue(
            sectionSelectedState({ applicationId: application?.id })
        );
        const usePartialValidation = useRecoilValue(getUseProblemsValidation);

        const [showProblemsValidation] = useRecoilState(
            getProblemsValidationMode
        );
        const problems = useRecoilValue(
            getProblemsBySection({
                applicationId: application?.id,
                section: problemType || sectionSelected,
            })
        );

        const placeholder = label ? i18n._(label) : '';

        if (!isEditing && fieldType === 'hidden') {
            return null;
        }

        if (!isEditing) {
            return (
                <CellData
                    ref={ref}
                    value={value}
                    fieldType={fieldType}
                    tx={placeholder}
                    options={options}
                    {...rest}
                    variant={getCellVariant(
                        isFieldInvalid(problems, name, isFieldInvalidCompareFn),
                        showValidationProblems,
                        showProblemsValidation,
                        usePartialValidation,
                        rest.variant,
                        rest.required
                    )}
                />
            );
        }

        return {
            hidden: (
                <>
                    <Controller
                        render={({ field }) => (
                            <input type="hidden" {...field} />
                        )}
                        name={name}
                        defaultValue={getDefaultValue(value)}
                    />
                </>
            ),
            readonly: (
                <>
                    <Controller
                        render={({ field }) => (
                            <input type="hidden" {...field} />
                        )}
                        name={name}
                        defaultValue={getDefaultValue(value)}
                    />
                    <CellData
                        value={value}
                        fieldType={fieldType}
                        tx={label}
                        options={options}
                        {...rest}
                    />
                </>
            ),
            text: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <TextInputComponent
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            textarea: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <TextAreaInputComponent
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            markupTextarea: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <MarkupTextAreaInputComponent
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                            ref={ref}
                        />
                    )}
                />
            ),
            email: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <TextInputComponent
                            type="email"
                            placeholder={placeholder || name}
                            autoComplete="email"
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            phone: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <PhoneInputComponent
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            money: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <MoneyInputComponent
                            integer={integer}
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            number: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <NumberInputComponent
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            integer={integer}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            simpleNumber: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <NumberInputComponent
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            integer={integer}
                            disableFormatting
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            percentage: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <PercentageInputComponent
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            integer={integer}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            select: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <Select
                            id={name}
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            options={options}
                            {...(rest as any)}
                            {...field}
                            groupedOptions={groupedOptions}
                        />
                    )}
                />
            ),
            boolean: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <Select
                            options={YES_NO_OPTIONS(i18n)}
                            hasBoolean
                            id={name}
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            yesNo: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <Select
                            options={YES_NO_STRING_OPTIONS(i18n)}
                            id={name}
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            date: (
                <Controller
                    name={name}
                    defaultValue={dateNormalizer(getDefaultValue(value))}
                    render={({ field, fieldState }) => (
                        <DateInputComponent
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            locale={i18n.locale}
                            {...(rest as any)}
                            {...field}
                            value={dateNormalizer(field.value)}
                            blockWeekends={blockWeekends}
                        />
                    )}
                />
            ),
            postalCode: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState }) => (
                        <PostalCodeInput
                            placeholder={placeholder || name}
                            error={fieldState.error}
                            {...(rest as any)}
                            {...field}
                        />
                    )}
                />
            ),
            checkbox: (
                <Controller
                    name={name}
                    defaultValue={getDefaultValue(value)}
                    render={({ field, fieldState, ...restRHF }) => (
                        <Label
                            name={`checkobox-${name}`}
                            css={{
                                fontSize: 'var(--font-size-0)',
                                fontWeight: 'var(--font-weight-6)',
                                pointerEvents: rest?.disabled ? 'none' : 'auto',
                            }}
                            {...rest}
                        >
                            <CheckboxComponent
                                id={`checkobox-${name}`}
                                placeholder={placeholder || name}
                                error={fieldState.error}
                                disabled={rest?.disabled}
                                {...(restRHF as any)}
                                {...field}
                                checked={field?.value ?? false} // This fixes an "uncontrolled input to controlled error" when the value is null or undefined
                                squareCheckbox={rest.squareCheckbox}
                            />
                            {placeholder || name}
                        </Label>
                    )}
                />
            ),
        }[fieldType];
    }
);
